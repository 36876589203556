import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const AutoLayout = styled.div`
  display: flex;
  flex-direction: ${props => props.direction};

  ${props => ({
    justifyContent: props.justifyContent,
    alignItems: props.alignItems,
    flex: props.flex,
    flexGrow: props.flexGrow,
    flexShrink: props.flexShrink,
    flexWrap: props.flexWrap,
  })};

  ${props => {
    if (!props.theme.flexGapPolyfill) {
      return { gap: props.gap };
    }

    if (props.direction === 'row') {
      return css`
        & > * + * {
          margin-left: ${props =>
            typeof props.gap === 'number' ? `${props.gap}px` : props.gap};
        }
      `;
    } else {
      return css`
        & > * + * {
          margin-top: ${props =>
            typeof props.gap === 'number' ? `${props.gap}px` : props.gap};
        }
      `;
    }
  }};

  ${props =>
    props.noItemMargin &&
    css`
      & > * {
        margin: 0;
      }
    `}
`;

AutoLayout.propTypes = {
  direction: PropTypes.string.isRequired,

  justifyContent: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
  ]),

  alignItems: PropTypes.oneOf([
    'stretch',
    'flex-start',
    'flex-end',
    'center',
    'stretch',
    'baseline',
  ]),

  gap: PropTypes.number,

  flex: PropTypes.any,
  flexGrow: PropTypes.number,
  flexShrink: PropTypes.number,

  noItemMargin: PropTypes.bool,
};

export default AutoLayout;
