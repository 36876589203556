/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import * as React from 'react';
import { ThemeProvider } from 'styled-components';

import { routeUpdateEmitter } from './src/components/Layout';
import theme from './src/theme';

export function onRouteUpdate({ location, prevLocation }) {
  routeUpdateEmitter.emit('routeUpdate', location.pathname);
}

export function wrapRootElement({ element }) {
  return (
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
  );
}
