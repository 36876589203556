import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { AutoLayout, BaseButton } from '@digigear/elements';

import Button from './Button';

import sponsor_1 from '../images/nailoong/header_sponsor_1.svg';
import sponsor_2 from '../images/nailoong/header_sponsor_2.svg';
import BaseHeader from './BaseHeader';

const NavLink = styled(BaseButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;

  transition: color 0.4s, background 0.4s;

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }

  &,
  &:visited,
  &:hover,
  &:active {
    color: ${props => props.theme.palette.secondary.main};
    text-decoration: none;
  }

  &:hover,
  &:active {
    color: ${props => props.theme.palette.secondary.dark};
    background: rgba(255, 255, 255, 0.1);
  }

  @media screen and (max-width: 1200px) {
    padding: 10px 8px;
  }
`;

const Logo_1 = () => (
  <Link to="/" className="logo" style={{ width: '230px' }}>
    <img src={sponsor_1} alt="Sponsor1" />
  </Link>
);

const Logo_2 = () => (
  <Link to="/" className="logo" style={{ width: '250px' }}>
    <img src={sponsor_2} alt="Sponsor2" />
  </Link>
);

const StyledHeader = styled(BaseHeader)`
  height: 80px;
  background: white;

  & .logo {
    max-width: 35vw;
    & img {
      width: 100%;
    }
  }

  & .hamburger-button {
    color: white;
  }

  & .hamburger-button-2 {
    background: white;
    color: ${props => props.theme.palette.secondary.main};
  }

  & > .content {
    height: 80px;
    gap: 40px;
    margin: 0;
    max-width: none;
  }

  &.menu-open {
    background-color: white;
  }

  @media screen and (max-width: 1420px) {
    & .dynamic_size {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1100px) {
    & > .content {
      gap: 10px;
    }
    & .dynamic_size {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 400px) {
    & > .content {
      gap: 10px;
    }
  }

  @media screen and (max-width: 820px) {
    & .narrow {
      display: none;
    }
  }
`;

const HamburgerMenu = styled(AutoLayout).attrs({
  direction: 'column',
})`
  position: relative;
  z-index: 1000;
  background: ${props => props.theme.palette.secondary.main};

  width: 100vw;
  padding: 12px;
  margin-left: -16px;
`;

const hamburgerMenu = () => {
  return (
    <HamburgerMenu className="narrow-only">
      <AutoLayout direction="column" alignItems="center">
        <NavLink className="hamburger-button" to="/#main">
          活動主頁
        </NavLink>
        <NavLink className="hamburger-button" to="/#info">
          賽事資訊
        </NavLink>
        <NavLink className="hamburger-button" to="/#runnerspack">
          選手包
        </NavLink>
        <NavLink className="hamburger-button" to="/#perks">
          限定優惠
        </NavLink>
        <NavLink className="hamburger-button" to="/#partners">
          活動夥伴
        </NavLink>
        <NavLink className="hamburger-button" to="/#tnc">
          注意事項
        </NavLink>
        <Button
          className="hamburger-button-2"
          style={{ height: '100%' }}
          href="https://www.klook.com/zh-HK/activity/112636-nailong-run/"
          rel="noreferrer"
          target="_blank"
        >
          立即報名
        </Button>
      </AutoLayout>
    </HamburgerMenu>
  );
};

const Header = () => {
  return (
    <StyledHeader
      fixed
      justifyChildren="flex-end"
      placeholderProps={{
        style: { height: 80 },
      }}
      hamburgerMenu={hamburgerMenu()}
    >
      <AutoLayout
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={24}
        style={{ width: '100%' }}
      >
        <Logo_1 />
        <AutoLayout
          className="narrow wide-only"
          direction="row"
          alignItems="center"
        >
          <NavLink className="dynamic_size" to="/#main">
            活動主頁
          </NavLink>
          <NavLink className="dynamic_size" to="/#info">
            賽事資訊
          </NavLink>
          <NavLink className="dynamic_size" to="/#runnerspack">
            選手包
          </NavLink>
          <NavLink className="dynamic_size" to="/#perks">
            限定優惠
          </NavLink>
          <NavLink className="dynamic_size" to="/#partners">
            活動夥伴
          </NavLink>
          <NavLink className="dynamic_size" to="/#tnc">
            注意事項
          </NavLink>
          <Button
            className="dynamic_size"
            style={{ height: '100%' }}
            href="https://www.klook.com/zh-HK/activity/112636-nailong-run/"
            rel="noreferrer"
            target="_blank"
          >
            立即報名
          </Button>
        </AutoLayout>
        <Logo_2 />
      </AutoLayout>
    </StyledHeader>
  );
};

export default Header;
