import React from 'react';
import styled from 'styled-components';

import { useTracking } from '../tracking';
import { useElementsConfig } from '../ElementsConfig';

const UnstyledButton = styled.button`
  // https://stackoverflow.com/a/45890842

  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: ${props => (props.disabled ? 'unset' : 'pointer')};
  outline: inherit;

  display: inline-flex;

  svg {
    display: block;
  }
`;

const BaseButton = React.forwardRef((props, ref) => {
  const { trackEvent } = useTracking();
  const { Link } = useElementsConfig();

  const handleClick = e => {
    if (props.disabled) {
      e.preventDefault();
    } else {
      if (props.track) {
        trackEvent(props.track);
      }
      if (props.onClick) {
        props.onClick(e);
      }
    }
  };

  let asComp = 'button';
  if (props.to) {
    asComp = Link;
  } else if (props.href) {
    asComp = 'a';
  }

  return (
    <UnstyledButton onClick={handleClick} {...props} as={asComp} ref={ref} />
  );
});

export default BaseButton;
