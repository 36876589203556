import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { MdMenu } from 'react-icons/md';

import AutoLayout from './AutoLayout';
import BaseButton from './BaseButton';

const Bar = styled.header`
  width: 100vw;

  position: relative;
  z-index: 1000;

  ${props =>
    props.fixed &&
    css`
      position: fixed;
      top: 0;
      left: 0;
    `}

  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;

  @media screen and (max-width: ${props => props.theme.dimensions.breakpoint}) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .spacer {
    flex: 1;
  }

  @media screen and (min-width: ${props =>
      parseInt(props.theme.dimensions.breakpoint, 10) + 1}px) {
    .narrow-only {
      display: none;
    }
  }

  @media screen and (max-width: ${props => props.theme.dimensions.breakpoint}) {
    .wide-only {
      display: none;
    }
  }
`;

const Content = styled(AutoLayout)`
  margin-left: auto;
  margin-right: auto;

  max-width: ${props => props.theme.dimensions.pageWidth};
  height: ${props => props.theme.dimensions.headerHeight};

  font-size: 19px;

  box-sizing: border-box;
`;

const StyledHamburgerButton = styled(BaseButton)`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: transform 0.4s;

  &.open {
    transform: rotate(180deg);
  }
`;

const HamburgerButton = ({ className, open, onClick }) => {
  return (
    <StyledHamburgerButton
      className={`${open ? 'open' : ''} ${className}`}
      onClick={onClick}
      aria-label="Menu"
    >
      <MdMenu />
    </StyledHamburgerButton>
  );
};

const HamburgerMenu = styled(AutoLayout).attrs({
  direction: 'column',
})`
  position: relative;
  z-index: 1000;
`;

const Outside = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;

const Placeholder = styled.div`
  height: ${props => props.theme.dimensions.headerHeight};
`;

const BaseHeader = ({
  cta,
  children,
  logo,
  fixed,
  justifyChildren,
  gap,
  className,
  style,
  placeholderProps,
}) => {
  const [atTop, setAtTop] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    setAtTop(window.scrollY <= 10);

    const handleScroll = e => {
      setAtTop(window.scrollY <= 10);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <Bar
        fixed={fixed}
        className={`${atTop ? 'at-top' : ''} ${
          menuOpen ? 'menu-open' : ''
        } ${className}`}
        style={style}
      >
        <Content
          className="content"
          direction="row"
          alignItems="center"
          gap={gap}
        >
          {/* Left */}
          {logo}

          {/* Center */}
          <AutoLayout
            direction="row"
            className="wide-only"
            justifyContent={justifyChildren || 'flex-start'}
            flex={1}
            style={{ alignSelf: 'stretch' }}
          >
            {children}
          </AutoLayout>
          <div className="spacer narrow-only" />

          {/* Right */}
          <AutoLayout direction="row">{cta}</AutoLayout>
          <HamburgerButton
            className="narrow-only"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <MdMenu />
          </HamburgerButton>
        </Content>
        {menuOpen && (
          <HamburgerMenu className="narrow-only">{children}</HamburgerMenu>
        )}
      </Bar>
      {menuOpen && (
        <Outside className="narrow-only" onClick={() => setMenuOpen(false)} />
      )}
      {fixed && <Placeholder {...placeholderProps} />}
    </>
  );
};

BaseHeader.propTypes = {
  cta: PropTypes.node,
  children: PropTypes.node,
  logo: PropTypes.node.isRequired,
  fixed: PropTypes.bool,
  justifyChildren: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
  gap: PropTypes.any,
  placeholderProps: PropTypes.any,
};

export default BaseHeader;
