import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import {
//   WalletModal,
//   WalletModalContext,
// } from '@solana/wallet-adapter-react-ui';

import { useTracking } from '@digigear/elements';

import mintNexus from '../images/mintNexus.svg';

// const StyledWalletModal = styled(WalletModal)`
//   .wallet-adapter-modal-wrapper {
//     position: relative;
//     border-radius: 0;

//     h1 {
//       background: linear-gradient(90deg, #3323aa 0%, #c6449e 100%);
//       padding: 20px;
//       margin: -30px -20px 20px -20px;
//       font-size: 24px;
//       font-weight: 900;
//       text-align: start;

//       &::after {
//         content: ' on Solana';
//       }

//       @media screen and (max-width: ${props =>
//           props.theme.dimensions.breakpoint}) {
//         font-size: 20px;
//       }
//     }

//     .wallet-adapter-modal-button-close {
//       top: 24px;
//       right: 24px;

//       transition: background 0.2s;
//       &:hover,
//       &:active {
//         background: rgba(255, 255, 255, 0.2);
//       }

//       display: inline-flex;

//       svg {
//         fill: white;
//       }
//     }

//     .wallet-adapter-button {
//       transition: background 0.2s;
//       &:hover {
//         background: rgba(255, 255, 255, 0.2);
//       }
//     }

//     &::after {
//       content: '';
//       display: block;

//       background: no-repeat center/100% url('${mintNexus}');
//       width: 89px;
//       height: 16px;

//       margin-top: 20px;
//     }
//   }

//   // .wallet-adapter-modal-overlay {
//   // }
// `;

const WalletModalProvider = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (visible) {
      trackEvent({ event: 'wallet_modal' });
    }
  }, [visible, trackEvent]);

  return <>{children}</>;
};

export default WalletModalProvider;
