import React from 'react';
import { Link } from 'gatsby';

const ElementsConfig = React.createContext({
  Link,
});

export default ElementsConfig;

export function useElementsConfig() {
  return React.useContext(ElementsConfig);
}
