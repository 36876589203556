import * as React from 'react';
import { cloneElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

const NoopTransition = ({ children, ...props }) => {
  const [transitionIn, setTransitionIn] = useState(false);

  useEffect(() => {
    setTransitionIn(true);
  }, []);

  return React.Children.map(children, c => {
    return typeof window !== 'undefined' ? (
      <CSSTransition in={transitionIn} timeout={200}>
        {cloneElement(c, props)}
      </CSSTransition>
    ) : (
      cloneElement(c, {
        ...props,
        className: [props.className || '', 'enter-done'].join(' '),
      })
    );
  });
};

const FadeInTransition = styled(NoopTransition)`
  opacity: 0;

  &.enter-active {
    opacity: 1;
    transition: opacity 0.2s;
  }

  &.enter-done {
    opacity: 1;
  }
`;

export default FadeInTransition;
