import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Section = styled.section`
  width: 100%;

  box-sizing: border-box;
  padding: 80px 32px 80px 32px;
  height: ${props =>
    props.height === 'full'
      ? `calc(100vh - ${props.theme.dimensions.headerHeight})`
      : 'auto'};

  @media screen and (max-width: ${props => props.theme.dimensions.breakpoint}) {
    padding: 40px 16px 40px 16px;
  }

  position: relative;

  overflow: hidden;
`;

const Content = styled.div`
  max-width: 1280px;

  margin-left: auto;
  margin-right: auto;

  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &.narrow {
    max-width: 1040px;
  }
`;

const ContentSection = ({
  children,
  className,
  height,
  containerDecors,
  narrow = false,
  ...props
}) => (
  <Section className={className || ''} height={height} {...props}>
    {containerDecors}
    <Content className={'content ' + (narrow ? 'narrow' : 'wide')}>
      {children}
    </Content>
  </Section>
);

ContentSection.propTypes = {
  children: PropTypes.node,
  height: PropTypes.oneOf(['full']),
  containerDecors: PropTypes.node,
  narrow: PropTypes.bool,
};

export default ContentSection;
