import { Link } from 'gatsby';
import EventEmitter from 'events';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import { FadeInTransition } from '@digigear/elements';

import '@fontsource/noto-sans-hk/500.css';
import '@fontsource/noto-sans-hk/900.css';
import 'normalize.css/normalize.css';
import 'react-toastify/dist/ReactToastify.css';
import '../fonts/pixelmplus12-bold.css';
import Header from './Header';
import WalletModalProvider from './WalletModalProvider';
import './layout.css';
import './toastify.css';
import scroll_to_top from '../images/nailoong/scroll_to_top.png';
import styled from 'styled-components';

export const routeUpdateEmitter = new EventEmitter();

const StyledLink = styled(Link)`
  position: fixed;
  right: 6vw;
  bottom: 4dvh;
  z-index: 1500;

  display: none;

  @media screen and (max-width: 800px) {
    display: initial;
  }
`;

const Scroll_to_top = () => (
  <StyledLink to="/">
    <img src={scroll_to_top} width={50} height={51} alt="stt" />
  </StyledLink>
);

const Layout = ({ children }) => {
  const [pathName, setPathName] = useState(null);

  const routeUpdateListener = useCallback(pathName => {
    setPathName(pathName);
  }, []);

  useEffect(() => {
    routeUpdateEmitter.on('routeUpdate', routeUpdateListener);

    return () => routeUpdateEmitter.off('routeUpdate', routeUpdateListener);
  }, [routeUpdateListener]);

  return (
    <>
      <WalletModalProvider>
        <Header />
        {/* When pathName changes, the FadeInTransition is remounted to trigger fade-in transition */}
        <FadeInTransition key={'path-' + pathName}>
          <div>{children}</div>
        </FadeInTransition>
      </WalletModalProvider>

      <ToastContainer
        position="top-center"
        autoClose={false}
        newestOnTop
        theme="dark"
      />
      <Scroll_to_top />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
