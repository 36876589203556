import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Anchor = styled.div`
  position: relative;
  top: -${props => props.theme.dimensions.headerHeight};
`;

/** Create an anchor target that considers the fixed height of the header bar. */
const OffsetAnchor = ({ id }) => (
  <div>
    <Anchor id={id} />
  </div>
);

OffsetAnchor.propTypes = {
  id: PropTypes.string.isRequired,
};

export default OffsetAnchor;
