import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const ResponsiveLayout = styled.div`
  display: flex;
  flex-direction: ${props => (props.reversed ? 'row-reverse' : 'row')};
  justify-content: center;
  align-items: center;

  ${props => {
    if (!props.theme.flexGapPolyfill) {
      return { gap: props.gap };
    }

    if (!props.reversed) {
      return css`
        & > * + * {
          margin-left: ${props =>
            typeof props.gap === 'number' ? `${props.gap}px` : props.gap};
        }
      `;
    } else {
      return css`
        & > * + * {
          margin-right: ${props =>
            typeof props.gap === 'number' ? `${props.gap}px` : props.gap};
        }
      `;
    }
  }};

  @media screen and (max-width: ${props =>
      props.breakpoint
        ? props.breakpoint + 'px'
        : props.theme.dimensions.breakpoint}) {
    flex-direction: column;

    ${props => {
      const { gap, ...vertical } = props.vertical || {};
      return vertical;
    }};

    ${props => {
      const gap = props.vertical?.gap ?? props.gap;
      if (!gap) {
        return {};
      }

      if (!props.theme.flexGapPolyfill) {
        return { gap };
      }

      return css`
        & > * + * {
          margin-left: 0;
          margin-right: 0;
          margin-top: ${props => (typeof gap === 'number' ? `${gap}px` : gap)};
        }
      `;
    }};
  }
`;

ResponsiveLayout.propTypes = {
  gap: PropTypes.number,
  reversed: PropTypes.bool,
  breakpoint: PropTypes.number,
  vertical: PropTypes.any,
};

export default ResponsiveLayout;
