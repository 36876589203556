import React from 'react';
import styled, { css } from 'styled-components';

import { BaseButton } from '@digigear/elements';

const StyledButton = styled(BaseButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 30px;
  border-radius: 1000px;

  transition: scale 0.2s;
  /* width: 172px; */

  background: ${props => props.theme.palette.secondary.main};

  &,
  &:visited,
  &:hover,
  &:active {
    scale: 1;
    text-decoration: none;
  }

  &:active {
    scale: 0.9;
  }

  .container {
    padding: 0;
    box-sizing: border-box;

    border-radius: 1000px;
  }
`;

const Button = ({ children, ...props }) => {
  return (
    <StyledButton {...props}>
      <div className="container">{children}</div>
    </StyledButton>
  );
};

export default Button;
