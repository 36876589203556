import * as dimensions from './components/dimensions';

const theme = {
  palette: {
    primary: {
      main: '#F7A036',
      dark: '#EE7325',
      light: '#FFFFFF'
    },
    secondary: {
      main: '#3A61AE',
      dark: '#24429A',
      light: '#FFFFFF'
    },
    pink: {
      main: '#EE79AE',
      dark: '#E93F95',
      light: '#FFFFFF'
    },
  },
  dimensions,
};

export default theme;
